@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');


.site_font{
    font-family: "Urbanist", sans-serif;
}

body {
    background-color: black;
    
}

.main_nav_bar {
    color: #171B10 !important;
    /* border-bottom: 1px solid rgb(252 ,253 ,199); */
    /* padding: 8px 5px ; */
}

.main_logo {
    width: 120px;
}

.main_logo img 
{
    width: 100%;
}

.nav-link{
    color: rgb(182 189 189 ) !important;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    /* margin: 5px 8px ; */
    font-family: "Urbanist", sans-serif;

}
.nav-link.active{
    color: #00fdfe !important;

}
.nav-link:hover{
    color: #00fdfe !important;
}



@media only screen and (max-width:700px){
    .nav-link{
        text-align: center !important;
    }
    .ehtereun_btn{
        width: 100%;
    }
}



.header_btn {
    font-size: .875rem;
    line-height: 1.25rem;
    color: #fafafa;
}
.create_btn{
    border: 1px solid #00fdfe;
    padding: .5rem .75rem;
    letter-spacing: .2;
    font-weight: 500;
    color: white !important;
    background-color: transparent !important;
    outline: none !important;
        border-radius: 20px;
}

.blast_btn {
    display: flex;
    /* gap: 10px; */
    align-items: center;
    justify-content: center;
   
    gap: .5rem;
    border-radius: 20px;
    border: 1px solid  rgb(36 56 56);
   
    background-color: rgb(12 19 19 );
    padding: .5rem 1.5rem;
 
    color: rgb(255 255 255 );
    
    font-weight: 500;
    line-height: 1;
    letter-spacing: .2px;
}

.he_img{
    width: 20px;

}

.header_main{
    position:sticky !important;
    top: 40px !important;
    width: 100% !important;
    background-color: black;
}



@media only screen and (max-width:700px){
    .header_main{
        position:sticky !important;
        top: 40px !important;
    }
}