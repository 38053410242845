.upper_landing_box {
  background: url(../Assets/basic-bg.webp);
  border: 1px solid rgb(36 56 56);
  border-radius: 1rem ;
  height: 10rem ;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 3rem ;
  vertical-align: middle;
  background-size: cover;
}

.landing_head{
  font-size: 2.25rem;
  line-height: 2.5rem;
  color: white;
  text-align: left;
  font-weight: 700;
}

.points_market {
  color: white;
  font-size: 36px;
  font-weight: 500;
}


.landcard{
  padding: 1rem;
  background-color: rgb(18 28 28 );
  border: 1px solid rgb(36 56 56 );
  border-radius: .75rem;
}


.lc_head {
  font-size: 1.5rem;
  line-height: 1rem;
  color: white;
}

.lc_link {
  color: rgb(145 155 155);
  font-size: .8rem;
  line-height: 1rem;
}
.lc_link:hover{
  color: rgb(145 155 155) !important;

}

.lc_right_box {
  padding: 0.5rem 1rem;
  background-color: rgb(24 37 37);
  border-radius: 1rem;
  text-align: end;
}
.m_status{
  color: rgb(145 155 155);
  font-size: .75rem;
  line-height: 1rem;
}
.status {
  color:white;
  font-size: 18px ;
}

.info_lc {
  color: rgb(145 155 155 );
  font-size: 16px;
  font-weight: 400;
  line-height: 24px   ;
}

.info_tm {
  color: white;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px   ;
  
}

.main_landing_page_here {
  padding: 60px  10px 100px 10px ;
}

.btn_build
{
  position: fixed;
  left: 2.5rem;
  bottom: 2.5rem;
  width: 200px;
}


@media only screen and (max-width:700px){
  .landing_head{
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
}