.m_bg {
  background-color: rgb(18 28 28);
  padding: 10px;
  border-radius: 0.75rem;
}

.main_market_page {
  padding: 70px 10px 20px 10px;
}

.p_points {
  font-size: 16px;
  color: white;
}

.mm_st {
  color: rgb(145 155 155);
  font-size: 12px;
}

.new_drop {
  color: white !important;
}
.dropdown-menu.show {
  background-color: #121c1c;
  width: 280px;
  padding: 10px ;
}

.open_st {
  background-color: rgb(0 253 254);
  color: white !important;
  border-radius: 20px;
  font-size: 12px ;
  border: none;
  padding: 5px;

}


.lprice {
    color: rgb(145 155 155);
    font-size: 16px;
}


.table-dark {
    --bs-table-color: #fff;
    --bs-table-bg: rgb(18,28,28) !important;
    --bs-table-border-color: #4d5154;
    --bs-table-striped-bg: #2c3034;
    --bs-table-striped-color: #fff;
    /* --bs-table-active-bged !important; */
    --bs-table-active-color: #fff;
    --bs-table-hover-bg: #323539;
    --bs-table-hover-color: #fff;
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color);
    font-family: "Urbanist", sans-serif !important;

}

.first_cell {
    font-size: 12px !important;
    color: #4BB10B !important;
}

.sec_tb_first_cell{
    font-size: 12px ;
    color: red;
}


.seb_cell{
    font-size: 12px !important;
}

.tb_parti{
   
    --tw-bg-opacity: .1;
    background-color: rgb(0 253 254 / var(--tw-bg-opacity));
    padding: 10px;
    border-radius: 200px;
    text-align: center;
    font-size: 12px;
    color: #00fdfe;
    font-weight: 600;

}

.tb_header{
    color: rgb(145 155 155) !important;
    font-size: 16px !important;
    font-weight: 500;
    font-family: "Urbanist", sans-serif;

}

.tb_btn_b_s{
    background-color: transparent;
    padding: 1px 30px ;
    color: white;
    text-transform: capitalize;
    text-align: center;
    border: 1px solid rgb(145 155 155) ;
    border-radius: 20px ;
}

.table_upper_{
  background-color: rgb(18 28 28);
  padding: 0.75rem 1rem;
border-top-left-radius: 15px;
border-top-right-radius: 15px;
display: flex;
justify-content: space-between;
align-items: center;
}

.lower_bu{
  font-size: .875rem;
        line-height: 1.25rem;
        color: rgb(22 225 91);
        padding: 4px .9rem;
        --tw-bg-opacity: .1;
        background-color: rgb(22 225 91 / var(--tw-bg-opacity));
        font-feature-settings: "clig" off, "liga" off;
        border-radius: 20px;
        font-weight: 500;
}

.up_right_arr {
  border: 1px solid rgb(145 155 155);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding:7px  ;
  color: rgb(145 155 155);
}


.days_ago {
  font-size: 12px;
  color: white;
}

.table>:not(caption)>*>* {
  padding: .8rem .5rem;
  color: var(--bs-table-color-state, var(--bs-table-color-type, var(--bs-table-color)));
  background-color: var(--bs-table-bg);
  border-bottom-width: var(--bs-border-width);
  box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
}




.without_contet{
  height: 170px !important  ;
}


@media only screen and (max-width:700){
  .table>:not(caption)>*>*{
    white-space: nowrap !important;
  }
}