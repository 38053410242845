.upper_header{
    background-color: rgb(252 252 3);
    padding:10px 20px  ;
}

.up_par {
    color: black;
    font-weight: 900;
    font-size: 14px ;
    font-family: "Urbanist", sans-serif;

}


.upper_header {
    position: fixed !important;
    top: 0px !important;
   
    height: 40px;
    width: 100% !important;
}


@media only screen and (max-width:700px) {
    .up_par{
        font-size: 12px !important;
    }
    
}